import config from './config';

export const getRssFeedData = async () => {
  console.log(config.apiGateway.URL)
  console.log("^v^v^v^v^v^v^v")
  console.log(process.env)
  let response = await fetch(config.apiGateway.URL);
  let data = await response.json();
  console.log(data)
  return(data)
}