import React, { Component } from "react";
import { getRssFeedData } from "./api"
import { useAsync, useInterval, useAsyncFn } from "react-use";

export default function RssFeed() {

  const [episodes, setEpisodes] = React.useState(null);


  React.useEffect(() => {
    getRssFeedData()
  }, [])

  if (!episodes) {
    return "loading ...";
  }

  return(
    <div>
    {episodes.map(episode=>
        <div key={episode.title}>
          {episode.title}
          <audio controls>
            <source src={episode.audioUrl} type={episode.audioType} />
          Your browser does not support the audio element.
          </audio>
        </div>
      )
    }
    </div>
  )
}