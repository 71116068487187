import React from 'react';
import RssFeed from './rss_feed'
import './App.css';
import logo from './901101Logo.png'

function App() {
  return (
    <div>
      <header className="App-header">
        <nav className="navbar navbar-light bg-light d-flex">
          <a className="navbar-brand ml-5" href="#">
            <img src={logo} width="180" height="180" className="d-inline-block align-top" alt=""/>
          </a>
          <li className="nav-item">
            <a className="nav-link" href="#">Grizzlies</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Tigers</a>
          </li>
        </nav>
        <RssFeed/>
      </header>

    </div>
  );
}

export default App;